import 'slick-carousel'
import breakpoint from './globals/breakpoints'
import Lightbox from './lightbox'

export default function Gallery() {
    init()
}

function init() {

    $('.gallery-wrapper').each(function () {

        var $slider = $(this),
            $dots = $slider.find('.slide-m-dots');

        $slider.find('.gallery').slick({
            dots: true,
            arrows: false,
            appendDots: $dots
        });
    });

    function mobileSlick(){

      $('.product-images-gallery').each(function () {

          var $products = $(this),
              $nArrow = $products.find('.product-image-next-btn'),
              $pArrow = $products.find('.product-image-prev-btn');

            $products.find('.product-image-container').slick({
              dots: false,
              arrows: true,
              mobileFirst: true,
              centerMode: true,
              infinite: true,
              nextArrow: $nArrow,
              prevArrow: $pArrow,
              responsive: [
                {
                    breakpoint: breakpoint.small,
                    settings: "unslick"
                }
              ]
          });
      });
    }

    var $windowWidth = $(window).width();
    if ($windowWidth < breakpoint.small) {
      mobileSlick();
    }

    $(window).resize(function(){
      
        var $windowWidth = $(window).width();
        if ($windowWidth < breakpoint.small) {

          if(!$('.product-image-container').hasClass("slick-initialized")) {
            mobileSlick();   
            Lightbox()
          }
        }
        else {
          if(!$('.product-image-container').hasClass("slick-initialized")) {
            Lightbox()
          }
        }
    });
}