import enquire from 'enquire.js'
import breakpoint from './globals/breakpoints'
import * as DataLayerBasket from '../modules/dataLayer/dataLayerBasket';

export default function Navigation() {
    toggleOtherMenu();
    toggleSearch();
    toggleBasket();
    desktopReset();
    toggleLanguageFilter();
    headerBasketRemove();
}

function toggleLanguageFilter() {
    var $languageFilter = $('.language-filter');
    $languageFilter.on("click", function () {

        $(this).toggleClass('language-active');

        document.querySelector('.language-dropdown').classList.toggle('language-filter-active');
    });
}

function toggleOtherMenu() {
    var $mobileTrigger = $('.menu-icon-wrapper');
    var $mobileChildLink = $('.nav-child-link');
    var $mobileChildBackLink = $('.back-link');

    $mobileTrigger.on("click",
        function (e) {
            $('.upper-nav').slideDown();
            document.querySelector('.menu-icon').classList.toggle('menu-icon-active');
            document.querySelector('.site-header-wrapper').classList.toggle('nav-active');
            document.querySelector('.site-header').classList.toggle('nav-active');
            document.querySelector('.basket').classList.remove('basket-active');
            $('body').removeClass("overlay-open");


            $('.basket').slideUp();

            if ($('.site-header-wrapper').hasClass('nav-active')) {
                // $('.upper-nav').slideUp(function () {
                    $('.primary-navigation').slideDown();
                // });
            } else {
                $('.primary-navigation').slideUp();
            //         $('.upper-nav').slideDown();
            //     });
            // }
            }

            $('.logo-mob').fadeToggle();

        });

    $mobileChildLink.on("click",
        function (e) {
            $(this).siblings(".sub-navigation").addClass("is-active");
        }
    );

    $mobileChildBackLink.on("click",
        function (e) {
            $(this).parent().removeClass("is-active");
        }
    );
};


function toggleSearch() {
    var $searchTrigger = $('.header-search-btn');
    var $searchCloseTrigger = $('.close-search, .close-search-mob');

    $searchTrigger.on('click',
        function (e) {
            $('.basket').slideUp();
            document.querySelector('.lower-nav').classList.add('search-active');
            document.querySelector('.header-search').classList.add('header-search-active');
            $( ".header-search #st" ).focus();
            if(window.innerWidth >= breakpoint.medium + 1) {
              document.body.classList.add("overlay-open");
            }
        });

    $searchCloseTrigger.on('click',
        function () {
            document.querySelector('.lower-nav').classList.remove('search-active');
            document.querySelector('.header-search').classList.remove('header-search-active');
            document.body.classList.remove("overlay-open");
        });

    document.body.onresize = function() {

      if(window.innerWidth >= breakpoint.medium + 1) {
        if(document.querySelector('.header-search').classList.contains('header-search-active')) {
            document.body.classList.add("overlay-open");
        }
        else {
          document.body.classList.remove("overlay-open");
        }
      }
      else {
        document.body.classList.remove("overlay-open");
      }
    };
}

function toggleBasket() {
    $(document).on('click', '.shopping-basket, .basket-close', function (e) {
        e.stopPropagation();
            
        if (parseInt($('.shopping-basket').data("qty")) > 0 || $('.shopping-basket').hasClass("basket-active")) {
                $('.basket').slideToggle();
                document.querySelector('.shopping-basket').classList.toggle('basket-active');
                document.body.classList.toggle("overlay-open");
            }
            else {
                var basketUrl = $('.shopping-basket').data("url");
                if (window.location.href.indexOf(basketUrl) > -1) {
                    return false;
                }
                else {
                    window.location.replace(basketUrl);
                }
            }
      });
}

function desktopReset() {
    $(window).on('resize', function () {
        var win = $(this); //this = window
        if (win.width() > breakpoint.medium) { /* ... */
            document.querySelector('.menu-icon').classList.remove('menu-icon-active');
            document.querySelector('.site-header-wrapper').classList.remove('nav-active');
            document.querySelector('.upper-nav').removeAttribute("style");
            document.querySelector('.logo-mob').removeAttribute("style");
            document.querySelector('.basket').removeAttribute("style");
            document.querySelector('.primary-navigation').removeAttribute("style");
        }
    });
}

function headerBasketRemove() {
    $(document).on("click", ".site-header .basket-item-remove a", function (e) {
        e.preventDefault()

        var unitPrice = parseFloat($(this).data("price")) / parseInt($(this).data("quantity"));

        var product = {
            item_id: $(this).data("sku"),
            item_name: $(this).data("name"),
            affiliation: "Victoria + Albert Baths",
            index: 0,
            currency: $(this).data("currency"),
            item_brand: $(this).data("name").split("-")[0].replace(/ /g, ''),
            item_category: $(this).data("category"),
            price: unitPrice,
            quantity: parseInt($(this).data("quantity"))
        };

        $.ajax({
            url: "/umbraco/Surface/Basket/Remove",
            type: "POST",
            dataType: "json",
            data: { orderLineId: $(this).data("lid"), nodeId: $(this).data("nid")  },
            success: function (data) {
                if (data.response == "success") {

                    DataLayerBasket.RemoveFromBasket(product);

                    //Update and display header basket
                    $(".site-header .header-icons .shopping-basket").find(".items").html(data.orderQty)
                    $('.shopping-basket').data("qty", data.orderQty)
                    $(".site-header .basket-wrapper").replaceWith(data.headerBasket);
                    $(".site-header .basket-wrapper .basket-error").html(data.validationMessage);
                    
                    if ($(".overlay-open").length < 1) {
                        $('.basket').slideUp();
                        $('body').removeClass("overlay-open");
                    }
                }
                else {
                    $(".site-header .basket-wrapper .basket-error").html(data.validationMessage);
                }
            },
            error: function (xhr) {
                console.log(xhr);
            }
        });
    });
}
