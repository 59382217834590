import 'magnific-popup'

export default function Lightbox() {
  init()
}

function init() {


  // $('.zoom-link').click(function (e) { 
  //   e.preventDefault();
  // });


function getforeGColor(rgb) { 
            var cols = rgb.match(/^rgb\((\d+), \s*(\d+), \s*(\d+)\)$/); 
            var b = 1; 
            var r = cols[1]; 
            var g = cols[2]; 
            var b = cols[3]; 
            var yiq = ((r*299)+(g*587)+(b*114))/1000;
            return (yiq >= 180) ? 'black' : 'white';
        }



 

    function colourLightbox() {
    $('.lightbox-colour').each(function() {
      var swatch = $(this);
      $(this).magnificPopup({       
        type: 'inline',        
        callbacks: {
          open: function() {

            var colour = swatch.data('colour');
            $('#colour-popup .swatch').css('backgroundColor', colour);

            $('#colour-popup h4').text(swatch.data('name'))
            $('#colour-popup .ral').text(swatch.data('ral'))

            
                var bgcolor = $('#colour-popup .swatch').css( "backgroundColor" );
                var textColor = getforeGColor(bgcolor);

                $('#colour-popup .swatch').css('color', textColor); 
                $('#colour-popup .swatch').find('h4').css('color', textColor); 
                $('#colour-popup .swatch').find('a').css('color', textColor); 
                $('#colour-popup .swatch').find('button').css('color', textColor); 
                 $('#colour-popup .swatch').find('button svg').css('fill', textColor);
                 $('#colour-popup .swatch').find('button svg').css('border-color', textColor); 

          
          },
          close: function() {
            // Will fire when popup is closed
          },change: function() {

           var colour = swatch.data('colour');
            $('#colour-popup .swatch').css('backgroundColor', colour);

            $('#colour-popup h4').text(swatch.data('name'))
            $('#colour-popup .ral').text(swatch.data('ral'))

            
                

             
          },

          // e.t.c.
        }
      });
    });
  }

  colourLightbox()

  function inlineLightbox() {
    $('.lightbox-inline').each(function() {
      $(this).magnificPopup({
        type: 'inline'
      });
    });
  }

  inlineLightbox()

  function downloadLightbox() {
      $('.lightbox-download').each(function () {
          $(this).magnificPopup({
              type: 'inline',
              disableOn: function () {
                  if ($(this)[0].ev != undefined && $(this)[0].ev.hasClass("disable-popup")) {
                      return false;
                  }
                  return true;
              }
          });
      });
  }

  downloadLightbox();

  var downloadText = "Download";
  var closeText = "Close";

  if($('.image-link')) {

    if(typeof $('.image-link').attr("data-download") !== typeof undefined && $('.image-link').attr("data-download") !== false) {
      downloadText = $('.image-link').attr("data-download");
    }

    if(typeof $('.image-link').attr("data-close") !== typeof undefined && $('.image-link').attr("data-close") !== false) {
      closeText = $('.image-link').attr("data-close");
    }
  }

  function singleLightbox() {

    if($('.image-link').length) {
        $('.image-link').magnificPopup({
          type:'image',
          closeMarkup: '<button title="%title%" type="button" class="mfp-close close-btn">Close <svg class="[ icon icon-close white-close mfp-close ]"><use xlink:href="#sprite-icon-close"></use></svg></button>',
          callbacks: {
            markupParse: function(template, values, item) {
    
              var imageUrl = item.src;
    
              var downloadSVG = '<svg class="[ icon icon-download ]"><use xlink:href="#sprite-icon-download"></use></svg>';
    
              template.append('<a class="mfp-download" href="' + imageUrl + '" download>' + downloadSVG + downloadText + '</a>');
      
            }
          }
        });
    }

  }

  singleLightbox()

  // function galleryLightbox() {
  //   $('.gallery-lightbox').each(function () {
  //     $(this).find('.gallery-image-link').magnificPopup({
  //       type: 'image',
  //       gallery:{
  //         enabled:true,
  //         tCounter: '',
  //       },
  //       closeMarkup: '<button title="%title%" type="button" class="mfp-close close-btn">Close <span>&#215;</span></button>',
  //       callbacks: {
  //         markupParse: function(template, values, item) {
  
  //           var imageUrl = values.img_replaceWith[0].currentSrc;
  
  //           template.append('<a class="mfp-download" href="' + imageUrl + '" download>Download</p>');
  
  //         }
  //       }
  //     });
  //   });
  // }

  // galleryLightbox()


    $('.lightbox-youtube').each(function () {
        $(this).magnificPopup({
            type: 'iframe',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close close-btn">Close <svg class="[ icon icon-close white-close mfp-close ]"><use xlink:href="#sprite-icon-close"></use></svg></button>',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                            '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
                        '</div>',
                patterns: {
                    youtube: {
                        index: 'youtube.com/',
                        id: 'v=',
                        src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
                    }
                }
            }
        });
    });
}