export function RemoveFromBasket(product) {

    var totalAmount = product.price * product.quantity;

    dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
    dataLayer.push({
        event: "remove_from_cart",
        currency: product.currency,
        value: totalAmount,
        ecommerce: {
            items: [product]
        }
    });
}



