import Cookies from 'js-cookie'

export default function ProductComparison() {
  init()
}

function init() {

  $(document).on("click", ".compare-product", function() {
    Comparison($(this));
    //changeCompare(false, $(this));
  });

  $(document).on("click", ".remove-comparison", function() {
    deleteComparison($(this));
    //changeCompare(true, $(this));
  });

  $(document).on("click", ".clear-comparison", function() {
    deleteComparison("", true);
  });

  if (Cookies.get('productComparison')) {
    if($(".product-comparison-container").length) {
      $(".product-comparison-container").removeClass("hidden");
    }
  }

  function deleteComparison(elem, clear) {

    if (Cookies.get('productComparison')) {

      $(".compare-product, .remove-comparison").each(function() {
        $(this).removeClass("disabled");
      });

      if(clear) {
        Cookies.remove('productComparison')
        $(".product-comparison-container").addClass("hidden");
        $(".remove-comparison").each(function() {
          $(this).removeClass("remove-comparison").addClass("compare-product");
        });
      }
      else {

        var id = elem.val();

        var comparisons = Cookies.get('productComparison').split("|");

        for (var i = comparisons.length - 1; i >= 0; --i) {
          if (comparisons[i] == id) {
              comparisons.splice(i,1);
          }
        }

        if(comparisons.length >= 1) {
          Cookies.set('productComparison', comparisons.join("|"))
          $('.comparison[data-id="' + id +'"]').remove();
        }
        else {
          Cookies.remove('productComparison');
          $(".product-comparison-container").addClass("hidden");
        }

        $('.remove-comparison[value="' + id + '"]').removeClass("remove-comparison").addClass("compare-product");

      }
    }
  }

  function Comparison(elem) {  

    var comparisons = []
    var id = elem.val();

    if (Cookies.get('productComparison')) {
      comparisons = Cookies.get('productComparison').split("|");
    }


    if(comparisons.length < 5) {
      if(!comparisons.includes(id)) {
  
        comparisons.push(id);
    
        Cookies.set('productComparison', comparisons.join("|"), {expires: 60})
    
        $.ajax({
            type: 'GET',
            url: "/umbraco/surface/ProductComparisonToolBar/ProductComparisons",
            success: function (data) {
              if($(".product-comparison-container").length) {
                $(".product-comparison").replaceWith(data);
                $(".product-comparison-container").removeClass("hidden");
              }

              if(comparisons.length >= 5) {
                $(".compare-product").each(function() {
                  $(this).addClass("disabled");
                });
              }
  
              $(elem).removeClass("compare-product").addClass("remove-comparison");
            },
            error: function (jqkhr) {
                console.log("ERROR " + jqkhr.responseText);
            }
        });
        
      }
    }

  }
}