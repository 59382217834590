
export function GetProducts(formFilters) {

    var products = document.querySelectorAll(".product-listing");

    if(products.length > 0) {

        var productList = [];

        products.forEach(function callback(product, index) {

            var item = {
                item_id: product.dataset.sku,
                item_name: product.dataset.name,
                affiliation: "Victoria + Albert Baths",
                currency: product.dataset.currency,
                index: index,
                item_brand: product.dataset.name,
                item_category: product.dataset.category,
                price: parseFloat(product.dataset.price),
                quantity: 1
            };

            if(formFilters != null && formFilters != undefined) {
                item = MergeObjects(item, formFilters);
            }

            productList.push(item);
        });

        dataLayer = dataLayer.filter(x => !x.ecommerce); // Clear the previous ecommerce object.
        dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                items: productList
            }
        });
    }
}

//export function OnProductClick(product) {

//    var item = {
//        item_name: product.dataset.name,
//        item_id: product.dataset.sku,
//        item_price: product.dataset.price,
//        item_category: product.dataset.category,
//        index: product.dataset.index,
//        item_page: GetCurrentPage(),
//    };
    
//    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//    dataLayer.push({
//      event: "select_item",
//      ecommerce: {
//        items: [item]
//      }
//    });
//}

export function MergeObjects(obj1,obj2){
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}

function GetCurrentPage() {
    if(document.querySelector(".pag-number.active") != null) {
        return parseInt(document.querySelector(".pag-number.active").innerHTML);
    }
    
    return 1;
}

export function ElementsToString(elements) {
    
    var array = [];

    for (let index = 0; index < elements.length; index++) {
        array.push(elements[index].innerText);
    }

    return array.toString();
}
  