import CustomSelect from '../modules/customSelect'
import * as DataLayerProductListings from './dataLayer/dataLayerProductListings';
import selectric from 'selectric'

export default function FilterForms() {
    Filter()
}

export function SubmitForm(isPageSize, newValue) {
    submit(isPageSize, newValue);
}

function Filter() {
    
    function clearForm(callback) {
        $(".filter-form").find("select").prop('selectedIndex', 0).selectric('refresh');
        $(".filter-form").find("option").removeAttr("selected");

        callback();
    }

    $(".filter-form").on("click", ".clear", function (e) {
        clearForm(function () {
            var formData = new FormData($(".filter-form")[0]);

            $.ajax({
                url: '',
                type: "POST",
                processData: false,
                contentType: false,
                enctype: 'multipart/form-data',
                data: formData,
                success: function (response) {
                    $("#Listings").replaceWith(response).after(DataLayerProductListings.GetProducts());

                    let asString = new URLSearchParams(formData).toString();
                    window.history.pushState("?" + asString, "", "?" + asString);

                    CustomSelect();
                    FilterForms();
                }
            });
        });
    });

    $("#pac-input").keyup(function () {
        if (document.getElementById("pac-input").value === "") {
            document.getElementById('search-map').disabled = true;
            $('.validation-msg').show();
        } 
    });

    submit();
}

function submit(isPageSize = false, newValue = 1) {
    var element = document.getElementById(isPageSize ? "ps" : "p");
    if (element) {
        element.value = newValue;
    }

    $(".filter-form-inline").on("submit", function (e) {
        e.preventDefault()

        var formData = new FormData($(this)[0]);

        var filters = {
            filter_style: DataLayerProductListings.ElementsToString($(this).find("#st option:selected")),
            filter_length: DataLayerProductListings.ElementsToString($(this).find("#ln option:selected")),
            filter_width: DataLayerProductListings.ElementsToString($(this).find("#wi option:selected")),
            filter_volume: DataLayerProductListings.ElementsToString($(this).find("#vol option:selected")),
            filter_finish: DataLayerProductListings.ElementsToString($(this).find("#fin option:selected"))
        }

        $.ajax({
            url: $(this).prop('action'),
            type: "POST",
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: formData,
            success: function (response) {
                $("#Listings").replaceWith(response).after(DataLayerProductListings.GetProducts(filters));

                let asString = new URLSearchParams(formData).toString();
                window.history.pushState("?" + asString, "", "?" + asString);
                CustomSelect()
                FilterForms()
                //$('.container.showroom-map, .container.showroom').slideDown();
                //if ($.isFunction($.fn.initMap)) {
                //    initMap()
                //}
            }
        });
    });
}