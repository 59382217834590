import persistentNav from './modules/persistentNav'
import Navigation from './modules/navigation'
import Footer from './modules/footer'
import Gallery from './modules/gallery'
import Lightbox from './modules/lightbox'
import Accordion from './modules/accordion'
import DownloadFiles from './modules/downloadFiles'
import '../../App_Plugins/UmbracoForms/Assets/themes/default/umbracoForms.js'
import 'jquery-validation'
import 'jquery-validation-unobtrusive'
import 'jquery-ajax-unobtrusive'
import Forms from './modules/forms'
import FilterForm from './modules/filterForm'
import ProductComparison from './modules/productComparison'
import CustomSelect from './modules/customSelect'

Navigation()
persistentNav()
Footer()
Gallery()
Lightbox()
Accordion()
DownloadFiles()
Forms()
FilterForm()
ProductComparison()
CustomSelect()

var Common = {}
var navScrollTop = 0

Common.init = function () {
    this.checkContent()
    this.persistentNav()
}

Common.checkContent = function () {

    // Finds and removes empty P tags
    $('p').each(function () {
        var $this = $(this)
        if ($this.html().replace(/\s|&nbsp;/g, '').length === 0) {
            $this.remove()
        }
    })
}

Common.persistentNav = function () {
    var siteHeader = $('#site-header')
    var start = $(window).scrollTop()

    if (start > 30) {
        if (start >= navScrollTop) {
            siteHeader.addClass('hide-element')
        } else {
            siteHeader.addClass('fixed')
            siteHeader.removeClass('hide-element')
            siteHeader.removeClass('top')
        }
    } else {
        siteHeader.removeClass('fixed')
        siteHeader.addClass('top')
    }

    /* Fix for iOS */
    if ($('html').hasClass('isios')) {
        if (siteHeader.hasClass('top')) {
            siteHeader.removeClass('hide-element')
        }
    }

    navScrollTop = start
}

$(document).ready(function () {
    Common.init()
})

$(window).scroll(function () {
    Common.persistentNav()
})

function getforeGColor(rgb) {
    var cols = rgb.match(/^rgb\((\d+), \s*(\d+), \s*(\d+)\)$/);
    var b = 1;
    var r = cols[1];
    var g = cols[2];
    var b = cols[3];
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 180) ? 'black' : 'white';
}


if ($(".swatch").length) {
    $('.swatch').each(function () {
        var bgcolor = $(this).css("background-color");
        var textColor = getforeGColor(bgcolor);

        $(this).css('color', textColor);
        $(this).find('h4').css('color', textColor);
        $(this).find('a').css('color', textColor);
    });
}