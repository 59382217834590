import 'parsleyjs'
import selectric from 'selectric'
import Pikaday from 'pikaday'


export default function Forms() {
    formStyling()
    formSubmission()
    fileUpload()
    //keyValidation() // Disabling for when 2 forms on the page.
}

var _formClass = '.umbraco-forms-form form';

var _form = $(_formClass);
_form.parsley();

window.Parsley.on('field:validated', function () {
    if (this.$element.is('select')) {
        if (this.isValid()) {
            $(this.$element).parents('.selectric-wrapper').find('.selectric').removeClass('parsley-error');
        }
        else {
            $(this.$element).parents('.selectric-wrapper').find('.selectric').addClass('parsley-error');
            $(this.$element).parents('.selectric-wrapper').append($(this.$element).parents('.selectric-wrapper').find('.selectric-hide-select .parsley-errors-list'));
        }
    }
});

function formStyling() {
    var inputs = $('input');
    
    
    inputs.on('input change', function() {
        var _val = $(this).val();
        if (_val.length) {
            $(this).addClass('has-value');
        } else {
            $(this).removeClass('has-value');
        }
    });

    if($('.datepickerfield').length){

        var picker = new Pikaday({ 
            field: $('.datepickerfield')[0],  
            format: 'D MMM YYYY', 
            reposition: 'top-left',
            onOpen: function() {
                     var pika = $(".pika-single");
                    var offset = pika.offset();
                    var newTop = offset.top - 140;        

                $(".pika-single").offset({ top: newTop}); 

                 }
        });
    }
}

function formSubmission() {

    $(document).on("submit", _formClass, function (e) {
        e.preventDefault();
    
        var _t = '#' + $(this).parent('div').attr('id');
        var _success = '.umbraco-forms-submitmessage';
        $('.loader').show();
        $(_t).find('input[type=submit], button[type=submit]').addClass('loading').html('<svg class="[ icon icon-loading ]"><use xlink:href="#sprite-icon-loading"></use></svg>');
        $.ajax({
            url: $(this).prop('action'),
            type: 'POST',
            enctype: 'multipart/form-data',
            data: new FormData($(this)[0]),
            processData: false,
            contentType: false,
            success: function (data) {
                var _data = $(data);
                var validationFailed = _data.find(_t);
                var formSuccess = _data.find(_success);

               

                $('.loader').hide();
                if (validationFailed.length > 0) {
                    
                    $(_t).html(validationFailed);

                } else if (formSuccess.length > 0) {
                    $(_t).addClass('hidden');
                    setTimeout(function () {
                        $(_t).html(formSuccess);

                        if ($('.container.form ').length > 0) {
                            $('html, body').animate({
                                scrollTop: ($('.container.form ').offset().top - 100)
                            }, 500);
                        }
                    }, 500)
                }

                setTimeout(function () {
                    $(_t).removeClass('hidden');

                     if ($('select').length > 0) {
                        $('select').selectric({
                          arrowButtonMarkup: '<svg class="[ icon icon-dropdown-arrow ]"><use xlink:href="#sprite-icon-dropdown-arrow"></use></svg>'
                        })
                      }
                      
                }, 500)
            }
        });
    });
}

function fileUpload() {

    $('.file-wrapper .button').on('click', function (e) {
        e.preventDefault();
        $(this).after('<input type="text" readonly name="' + $(this).attr("name") + '" hidden value=""/>');
        $(this).prev('input[type=file]').trigger('click');
    });

    $('.umbraco-forms-field input[type=file]').on('change', function () {
        if ($(this).get(0).files.length !== 0) {
            $('.file-wrapper').find('input:text').val($(this).get(0).files[0].name);
        }

        var _files = $(this).get(0).files;
        var _html = '<li>Chosen files</li>';

        if (_files.length > 0) {
            for(var i = 0; i < _files.length; i++) {
                _html += '<li>' + _files[i].name + '</li>';
            }
    
            $(this).parents('.file-wrapper').next('.uploaded-files').removeClass('hidden').html( _html );
        } else {
            $(this).parents('.file-wrapper').next('.uploaded-files').html('');
        }

    });

}

function keyValidation() {
    _form.validate({
        onkeyup: function (input) {

            $(input).removeClass('valid valid-input invalid-input');
            validateInput(input);

        },
        onfocusout: function (input) {

            validateInput(input);

        }
    })
}

function validateInput(input) {
  
    if ($(input).data('regex')) {
        var _regex = new RegExp($(input).data('regex'));

        if (_regex.test($(input).val()) && $(input).val().length > 0) {
            $(input).addClass('valid-input');
        } else {
            $(input).removeClass('valid valid-input');
            $(input).addClass('invalid-input');
        }
    } else if ($(input).val().length > 0) {
        $(input).addClass('valid-input');
    } else {
        $(input).removeClass('valid valid-input');
        $(input).addClass('invalid-input');
    }
}


